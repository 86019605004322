import React from "react";
import "./ConfigModal.css";

const TokensModal = (props) => {
  const tokens = props.tokens || [];
  return (
    <div className="modaly-tokens" onClick={props.onClose}>
      <div className="modaly-closer" onClick={props.onClose}></div>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-body">
          <h4 className="titleHeader">Tokens</h4>
          <br />
          <ul className="row ul-token">
            {tokens.map((token) => {
              return (
                token !== props.desabledToken && (
                  <li
                    className="li-token"
                    key={`${token.address}`}
                    onClick={() => {
                      props.handleSelectToken(token);
                      props.onClose();
                    }}
                  >
                    <img src={token.icon} alt=" " style={{borderRadius: "50%"}} />
                    <span>{token.symbol}</span>
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TokensModal;
