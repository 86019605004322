import React from "react";
import "./ConfigModal.css";

const ChainsModal = (props) => {
  const chains = props.chains || [];
  return (
    <div className="modaly-tokens" onClick={props.onClose}>
      <div className="modaly-closer" onClick={props.onClose}></div>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-body">
          <h4 className="titleHeader">Chains</h4>
          <br />
          <ul className="row ul-token">
            {chains.map((chain) => {
              return (
                chain.name !== props.desabledToken.name && (
                  <li
                    className="li-token"
                    key={`chains_${chain.name}`}
                    onClick={() => {
                      props.handleSelectToken(chain);
                      props.onClose();
                    }}
                  >
                    <img src={chain.src} alt="" style={{borderRadius: "50%"}} />
                    <span>{chain.name}</span>
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChainsModal;
