import React from "react";
import { useSpring, animated } from "react-spring";
import { useInView } from 'react-intersection-observer';

const FadeIn = ({ children, className, type }) => {

    const [ref, inView] = useInView({
        triggerOnce: true,
      });
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: inView ? 1 : 0 },
    config: { duration: 750 },
  });
  const propsFL = useSpring({
    from: { transform: "translateX(-100%)" },
    to: { transform: inView ? "translateX(0%)" : "translateX(-100%)" },
    config: { duration: 750 },
  });
  const propsFR = useSpring({
    from: { transform: "translateX(100%)" },
    to: { transform: inView ? "translateX(0%)" : "translateX(100%)" },
    config: { duration: 750 },
  });
  const propsFT = useSpring({
    from: { transform: "translateY(-100%) scale(0)" },
    to: { transform: inView ? "translateY(0%) scale(1)" : "translateY(-100%) scale(0)" },
    config: { duration: 750 },
  });
  const propsFB = useSpring({
    from: { transform: "translateY(100%)" },
    to: { transform: inView ? "translateY(0%)" : "translateY(100%)" },
    config: { duration: 750 },
  });
  switch (type) {
    case "fadeleft":
      return (
        <animated.div  ref={ref} className={className} style={propsFL}>
          {children}
        </animated.div>
      );
    case "faderight":
      return (
        <animated.div  ref={ref} className={className} style={propsFR}>
          {children}
        </animated.div>
      );
    case "fadetop":
      return (
        <animated.div  ref={ref} className={className} style={propsFT}>
          {children}
        </animated.div>
      );
    case "fadebottom":
      return (
        <animated.div  ref={ref} className={className} style={propsFB}>
          {children}
        </animated.div>
      );
    default:
      return (
        <animated.div  ref={ref} className={className} style={props}>
          {children}
        </animated.div>
      );
  }
};

export default FadeIn;
