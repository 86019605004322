import Home from "./pages/Home";
import DappSwap from "./pages/Dapp/DappSwap";
import DappBridge from "./pages/Dapp/DappBridge";
import DappEarn from "./pages/Dapp/DappEarn";
import DappMarket from "./pages/Dapp/DappMarket";

import imgEthereum from "./assets/images/ethereum.webp";
import imgBSC from "./assets/images/bnb.webp";
import imgPolygon from "./assets/images/matic.png"

import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import { tokensList } from "./tokens";

import BeatLoader from "react-spinners/BeatLoader";
import {
  getInputContract,
  getOutputContract,
  getPrice,
  runSwap,
} from "./AlphaRouterService";

function App() {
  const [provider, setProvider] = useState(undefined);
  const [signer, setSigner] = useState(undefined);
  const [signerAddress, setSignerAddress] = useState(undefined);

  const [slippageAmount, setSlippageAmount] = useState(2);
  const [deadlineMinutes, setDeadlineMinutes] = useState(10);
  const [showModal, setShowModal] = useState(undefined);

  const [inputAmount, setInputAmount] = useState(undefined);
  const [outputAmount, setOutputAmount] = useState(undefined);
  const [aproveAmount, setAproveAmount] = useState(undefined);
  const [currentRoute, setCurrentRoute] = useState(undefined);
  const [transaction, setTransaction] = useState(undefined);
  const [loading, setLoading] = useState(undefined);
  const [ratio, setRatio] = useState(undefined);
  const [inputContract, setInputContract] = useState(undefined);
  const [outputContract, setOutputContract] = useState(undefined);
  const [tokenInAmount, setTokenInAmount] = useState(undefined);
  const [tokenOutAmount, setTokenOutAmount] = useState(undefined);

  const [inputAux, setInputAux] = useState(undefined);
  const [tokenInput, setTokenInput] = useState(tokensList[0]);

  const [tokenOutput, setTokenOutput] = useState(tokensList[1]);

  const chains = [
    {
      src: imgEthereum,
      name: "Ethereum",
    },
    { src: imgBSC, name: "BNB Chain" },
    { src: imgPolygon, name: "Polygon" },
  ];
  const [chainSelected, setChainSelected] = useState(chains[0]);
  useEffect(() => {
    const onLoad = async () => {
      const provider = await new ethers.providers.Web3Provider(window.ethereum);
      setProvider(provider);

      const inputContract = getInputContract(tokenInput);
      setInputContract(inputContract);

      const outputContract = getOutputContract(tokenOutput);
      setOutputContract(outputContract);
    };
    onLoad();
  }, []);

  useEffect(() => {
    const AuxInputContract = getInputContract(tokenInput);
    setInputContract(AuxInputContract);

    const AuxOutputContract = getOutputContract(tokenOutput);
    setOutputContract(AuxOutputContract);
    // if (signer !== undefined) {
    //   signer.getAddress().then((address) => {
    //     setSignerAddress(address);

    //     // todo: connect weth and uni contracts
    //     AuxInputContract.balanceOf(address).then((res) => {
    //       setTokenInAmount(Number(ethers.utils.formatEther(res)));
    //     });
    //     AuxOutputContract.balanceOf(address).then((res) => {
    //       setTokenOutAmount(Number(ethers.utils.formatEther(res)));
    //     });
    //   });
    // }
    reset();
  }, [tokenInput, tokenOutput]);

  const getSigner = async (provider) => {
    try {
      provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      setSigner(signer);
    } catch (err) {
      console.log(err);
    }
  };

  const isConnected = () => signer !== undefined;

  const getWalletAddress = () => {
    try {
      signer.getAddress().then((address) => {
        setSignerAddress(address);

        // todo: connect weth and uni contracts
        inputContract.balanceOf(address).then((res) => {
          setTokenInAmount(Number(ethers.utils.formatEther(res)));
        });
        outputContract.balanceOf(address).then((res) => {
          setTokenOutAmount(Number(ethers.utils.formatEther(res)));
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  if (signer !== undefined) {
    getWalletAddress();
  }

  const getSwapPrice = (inputAmount) => {
    setLoading(true);
    setInputAmount(inputAmount);

    const swap = getPrice(
      inputAmount,
      slippageAmount,
      Math.floor(Date.now() / 1000 + deadlineMinutes * 60),
      signerAddress,
      tokenInput,
      tokenOutput
    ).then((data) => {
      setTransaction(data[0]);
      setOutputAmount(data[1]);
      setRatio(data[2]);
      setAproveAmount(data[3]);
      setCurrentRoute(data[4]);
      setLoading(false);
    });
  };

  const onChangeTokens = () => {
    try {
      const token0 = tokenInput;
      const token1 = tokenOutput;
      setTokenInput(token1);
      setTokenOutput(token0);
    } catch (err) {
      console.log(err);
    }
  };
  const reset = () => {
    setTransaction(undefined);
    setOutputAmount(undefined);
    setRatio(undefined);
    setAproveAmount(undefined);
    setCurrentRoute(undefined);
    setInputAux("");
    setOutputAmount("");
    setLoading(false);
  };
  const runSwapTokens = (transaction, signer, amountIn, token0) => {
    runSwap(transaction, signer, amountIn, token0);
    reset();
  };
  return (
    <div className="App">
      {/* <Home/> */}
      {/* <DappSwap/> */}
      {/* <DappPool/> */}
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            path="/swap"
            element={
              <DappSwap
                showModal={showModal}
                setShowModal={setShowModal}
                setDeadlineMinutes={setDeadlineMinutes}
                deadlineMinutes={deadlineMinutes}
                setSlippageAmount={setSlippageAmount}
                slippageAmount={slippageAmount}
                input={{
                  field: "input",
                  tokenName: tokenInput.symbol,
                  getSwapPrice: getSwapPrice,
                  signer: signer,
                  balance: tokenInAmount,
                }}
                output={{
                  field: "output",
                  tokenName: tokenOutput.symbol,
                  value: outputAmount,
                  signer: signer,
                  balance: tokenOutAmount,
                  spinner: BeatLoader,
                  loading: loading,
                }}
                isConnected={isConnected}
                runSwap={runSwapTokens}
                transaction={transaction}
                signer={signer}
                aproveAmount={aproveAmount}
                provider={provider}
                signerAddress={signerAddress}
                getSigner={getSigner}
                ratio={ratio}
                estimatedGasUsd={currentRoute?.estimatedGasUsedUSD?.toExact()}
                loading={loading}
                tokenInput={tokenInput}
                setTokenInput={setTokenInput}
                tokenOutput={tokenOutput}
                setTokenOutput={setTokenOutput}
                onChangeTokens={onChangeTokens}
                inputAux={inputAux}
                setInputAux={setInputAux}
                chains={chains}
                chainSelected={chainSelected}
                setChainSelected={setChainSelected}
              />
            }
          />
           <Route
            path="/bridge"
            element={
              <DappBridge
                showModal={showModal}
                setShowModal={setShowModal}
                setDeadlineMinutes={setDeadlineMinutes}
                deadlineMinutes={deadlineMinutes}
                setSlippageAmount={setSlippageAmount}
                slippageAmount={slippageAmount}
                input={{
                  field: "input",
                  tokenName: tokenInput.symbol,
                  getSwapPrice: getSwapPrice,
                  signer: signer,
                  balance: tokenInAmount,
                }}
                output={{
                  field: "output",
                  tokenName: tokenOutput.symbol,
                  value: outputAmount,
                  signer: signer,
                  balance: tokenOutAmount,
                  spinner: BeatLoader,
                  loading: loading,
                }}
                isConnected={isConnected}
                runSwap={runSwapTokens}
                transaction={transaction}
                signer={signer}
                aproveAmount={aproveAmount}
                provider={provider}
                signerAddress={signerAddress}
                getSigner={getSigner}
                ratio={ratio}
                estimatedGasUsd={currentRoute?.estimatedGasUsedUSD?.toExact()}
                loading={loading}
                tokenInput={tokenInput}
                setTokenInput={setTokenInput}
                tokenOutput={tokenOutput}
                setTokenOutput={setTokenOutput}
                onChangeTokens={onChangeTokens}
                inputAux={inputAux}
                setInputAux={setInputAux}
                chains={chains}
                chainSelected={chainSelected}
                setChainSelected={setChainSelected}
              />
            }
          />
          <Route
            path="/earn"
            element={
              <DappEarn
                signerAddress={signerAddress}
                isConnected={isConnected}
                getSigner={getSigner}
                provider={provider}
                chains={chains}
                chainSelected={chainSelected}
                setChainSelected={setChainSelected}
              />
            }
          />
          <Route
            path="/market"
            element={
              <DappMarket
                signerAddress={signerAddress}
                isConnected={isConnected}
                getSigner={getSigner}
                provider={provider}
                chains={chains}
                chainSelected={chainSelected}
                setChainSelected={setChainSelected}
              />
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
