import { AlphaRouter } from "@uniswap/smart-order-router";
import { Token, CurrencyAmount, TradeType, Percent } from "@uniswap/sdk-core";
import { ethers, BigNumber } from "ethers";
import JSBI from "jsbi";
import ERC20ABI from "./abi.json";

const V3_SWAP_ROUTER_ADDRESS = "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45";
const REACT_APP_INFURA_URL_TESTNET = process.env.REACT_APP_INFURA_URL_TESTNET;

const chainId = 1;

const web3Provider = new ethers.providers.JsonRpcProvider(
  REACT_APP_INFURA_URL_TESTNET
);
const router = new AlphaRouter({ chainId: chainId, provider: web3Provider });

export const getInputContract = (tokenData) =>
  new ethers.Contract(tokenData.address, ERC20ABI, web3Provider);

export const getOutputContract = (tokenData) =>
  new ethers.Contract(tokenData.address, ERC20ABI, web3Provider);

export const getPrice = async (
  inputAmount,
  slippageAmount,
  deadline,
  walletAddress,
  token0,
  token1
) => {
  const token0Data = token0;
  const token1Data = token1;

  const percentSlippage = new Percent(slippageAmount, 100);
  const wei = ethers.utils.parseUnits(
    inputAmount.toString(),
    token0Data.decimals
  );
  const currencyAmount = CurrencyAmount.fromRawAmount(
    new Token(
      chainId,
      token0Data.address,
      token0Data.decimals,
      token0Data.symbol,
      token0Data.name
    ),
    JSBI.BigInt(wei)
  );
  console.log(
    "debug 1",
    currencyAmount,
    new Token(
      chainId,
      token1Data.address,
      token1Data.decimals,
      token1Data.symbol,
      token1Data.name
    ),
    TradeType.EXACT_INPUT,
    {
      recipient: walletAddress,
      slippageTolerance: percentSlippage,
      deadline: deadline,
    }
  );
  const route = await router.route(
    currencyAmount,
    new Token(
      chainId,
      token1Data.address,
      token1Data.decimals,
      token1Data.symbol,
      token1Data.name
    ),
    TradeType.EXACT_INPUT,
    {
      recipient: walletAddress,
      slippageTolerance: percentSlippage,
      deadline: deadline,
      type: 1,
    }
  );

  const transaction = {
    data: route.methodParameters.calldata,
    to: V3_SWAP_ROUTER_ADDRESS,
    value: BigNumber.from(route.methodParameters.value),
    from: walletAddress,
    gasPrice: BigNumber.from(route.gasPriceWei),
    gasLimit: ethers.utils.hexlify(1000000),
  };

  const quoteAmountOut = route.quote.toFixed(6);
  const ratio = (quoteAmountOut / inputAmount).toFixed(6);
  console.log("debug 2", transaction, ratio);
  return [transaction, quoteAmountOut, ratio, inputAmount, route];
};

export const runSwap = async (transaction, signer, amountIn, token0) => {
  const approvalAmount = ethers.utils.parseUnits(amountIn, 18).toString();
  const contract0 = getInputContract(token0);
  await contract0
    .connect(signer)
    .approve(V3_SWAP_ROUTER_ADDRESS, approvalAmount);

  signer.sendTransaction(transaction);
};
