import React, { useEffect, useState } from "react";
import "./styles.css";
import "../dapp.css";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import iconBalance from "../../../assets/images/icons/blue.png";
import iconUsdt from "../../../assets/images/icons/usdt.svg";

import settingsIcon from "../../../assets/settings.svg";
import ConfigModalBridge from "../../../components/ConfigModalBridge";
import TokensModal from "../../../components/TokensModal";
import ChainsModal from "../../../components/ChainsModal";
import ReactLoading from "react-loading";

import { tokensList } from "../../../tokens";

const DappBridge = ({
  showModal,
  setShowModal,
  setDeadlineMinutes,
  deadlineMinutes,
  input,
  output,
  onChangeTokens,
  isConnected,
  runSwap,
  transaction,
  signer,
  aproveAmount,
  getSigner,
  provider,
  signerAddress,
  loading,
  tokenInput,
  setTokenInput,
  tokenOutput,
  setTokenOutput,
  inputAux,
  setInputAux,
  chains,
  setChainSelected,
  chainSelected,
}) => {
  const [showTokensModal, setShowTokensModal] = useState(false);
  const [showChainsModal, setShowChainsModal] = useState(false);
  const isConnectedBoolean = isConnected();
  var displayAddress = `${signerAddress?.substring(0, 10)}...`;
  const [chainDropDown, setChainDropDown] = useState(false);

  const [chainTo, setChainTo] = useState(chains[1]);
  const [chainFrom, setChainFrom] = useState([0]);
  const ratio = null;
  const estimatedGasUsd = null;
  const [slippageAmount, setSlippageAmount] = useState(2);
  useEffect(() => {
    setChainTo(chains[1]);
    setChainFrom(chains[0]);
  }, []);
  const onChangeChains = () => {
    const chainToAux = chainTo;
    const chainFromAux = chainFrom;
    setChainFrom(chainToAux);
    setChainTo(chainFromAux);
  };
  const openTokensModal = (type) => {
    setShowTokensModal(type);
  };
  const openChainsModal = (type) => {
    setShowChainsModal(type);
  };
  useEffect(() => {
    if (isConnectedBoolean == true) {
      displayAddress = `${signerAddress?.substring(0, 10)}...`;
      // getPrice(inputAux);
    }
  }, [isConnectedBoolean]);
  return (
    <>
      <Header
        isConnected={isConnected}
        displayAddress={displayAddress}
        getSigner={getSigner}
        provider={provider}
        chains={chains}
        chainSelected={chainSelected}
        setChainSelected={setChainSelected}
      />
      <section className="dapp-section">
        <div className="w-full flex justify-center">
          <div className="pb-3 pp-card bg-water-200 bg-opacity-[0.07] text-water-300 pb-3 bl-0001">
            <div className="border-b border-water-500">
              <div data-test="action-selector">
                <div
                  role="tablist"
                  aria-orientation="horizontal"
                  className="flex pp-tab-items relative"
                  modelvalue="0"
                  variant="text"
                >
                  <div
                    className="pp-tab pp-btn relative flex-1 !border px-2.5 py-1.5 text-base rounded-2xl text-water-300 enabled:hover:text-water-200 focus:text-water-200 transition pt-4 !text-white !font-medium"
                    id="headlessui-tabs-tab-33"
                    role="tab"
                    aria-selected="true"
                    tabindex="0"
                    text="Bridge"
                    name="ProBridge"
                  >
                    <span>Bridge</span>
                    <div className="absolute -bottom-[0.5px] h-0.5 w-full translate-y-full transform rounded-full transition bg-white"></div>
                  </div>

                  <button
                    className="configButton"
                    onClick={() => setShowModal(true)}
                  >
                    <img width="24px" height="24px" src={settingsIcon} alt="" />
                  </button>
                  {showModal && (
                    <ConfigModalBridge
                      onClose={() => {
                        setShowModal(false);
                      }}
                      setSlippageAmount={setSlippageAmount}
                      slippageAmount={slippageAmount}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="pt-6">
              <div>
                <div data-v-bd3d1223="">
                  <form novalidate="">
                    <div className="pp-card-body pb-8 text-water-300">
                      <div className="flex flex-col">
                        <div className="token-select">
                          <div className="text-base font-medium text-primary">
                            <span className="inline-block">Token</span>
                            <button
                              type="button"
                              onClick={() => openTokensModal("token")}
                              for=""
                              className="flex h-full w-full items-center justify-between px-2.5 py-1 gap-x-1 rounded-xl border-water-800 border items-center h-12"
                            >
                              <div className="inline-flex items-center overflow-x-hidden shrink">
                                <img
                                  src={tokenInput.icon}
                                  alt={tokenInput.symbol || "Select Token"}
                                  className="inline-block h-6 w-6 max-w-none shrink-0 bl-0002"
                                  style={{borderRadius: "50%"}}
                                />
                                <div className="ml-2 flex flex-col w-full overflow-x-hidden">
                                  <span className="inline-flex items-center w-full overflow-x-hidden font-semibold text-base">
                                    <span className="truncate">
                                      {tokenInput.symbol || "Select Token"}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <svg
                                width="14"
                                height="8"
                                viewBox="0 0 14 8"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                className="shrink-0"
                              >
                                <path d="M7.00023 7.71309L13.0102 1.70309L11.5972 0.288086L7.00023 4.88809L2.40423 0.288086L0.990234 1.70209L7.00023 7.71309Z"></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="order-1">
                          <div data-test="input">
                            <div className="flex">
                              <div className="">
                                <div className="text-base font-medium text-primary">
                                  <span className="inline-block">From</span>
                                </div>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                {(input.balance || input.balance == 0) && (
                                  <div className="font-medium">
                                    Balance:{" "}
                                    <div
                                      className="inline-block"
                                      data-test="balance"
                                    >
                                      <div className="flex items-center gap-x-1 whitespace-nowrap">
                                        <span
                                          title="0"
                                          className="whitespace-nowrap"
                                          style={{ marginLeft: "2px" }}
                                        >
                                          {input.balance?.toFixed(4)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mt-2.5 flex h-12 items-center rounded-xl border text-white bg-water-900 bg-opacity-40 border-water-800">
                              <div className="w-1/2 h-full border-water-800">
                                <button
                                  type="button"
                                  onClick={() => openChainsModal("from")}
                                  for=""
                                  className="flex h-full w-full items-center justify-between px-2.5 py-1 gap-x-1"
                                >
                                  <div className="inline-flex items-center overflow-x-hidden shrink">
                                    <img
                                      src={chainFrom.src}
                                      alt={chainFrom.name || "Select Chain"}
                                      className="inline-block h-6 w-6 max-w-none shrink-0 bl-0002"
                                    />
                                    <div className="ml-2 flex flex-col w-full overflow-x-hidden">
                                      <span className="inline-flex items-center w-full overflow-x-hidden font-semibold text-base">
                                        <span className="truncate">
                                          {chainFrom.name || "Select Chain"}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <svg
                                    width="14"
                                    height="8"
                                    viewBox="0 0 14 8"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="shrink-0"
                                  >
                                    <path d="M7.00023 7.71309L13.0102 1.70309L11.5972 0.288086L7.00023 4.88809L2.40423 0.288086L0.990234 1.70209L7.00023 7.71309Z"></path>
                                  </svg>
                                </button>
                                {showTokensModal === "token" && (
                                  <TokensModal
                                    onClose={() => setShowTokensModal(false)}
                                    handleSelectToken={setTokenInput}
                                    tokens={tokensList}
                                  />
                                ) }
                                {showChainsModal === "from" ? (
                                  <ChainsModal
                                    onClose={() => setShowChainsModal(false)}
                                    handleSelectToken={setChainFrom}
                                    chains={chains}
                                    desabledToken={chainTo}
                                  />
                                ) : (
                                  showChainsModal === "to" && (
                                    <ChainsModal
                                      onClose={() => setShowChainsModal(false)}
                                      handleSelectToken={setChainTo}
                                      chains={chains}
                                      desabledToken={chainFrom}
                                    />
                                  )
                                )}
                              </div>
                              <div className="relative w-1/2 h-full overflow-hidden border-l border-water-800">
                                <label
                                  for="pp-token-amount-input-2828"
                                  className="flex flex-col justify-center h-full"
                                >
                                  <input
                                    className="pp-input-field w-full py-0 pl-2 input-number pr-1 text-base font-semibold bg-transparent border-none placeholder:font-light focus:outline-none focus:ring-0 placeholder:text-water-500 bl-0003"
                                    step="any"
                                    placeholder="0.00"
                                    autocomplete="off"
                                    type="text"
                                    inputmode="decimal"
                                    id="pp-token-amount-input-2828"
                                    data-test="input"
                                    value={inputAux}
                                    onChange={(e) => {
                                      setInputAux(e.target.value);
                                    }}
                                    // onBlur={(e) => getPrice(e.target.value)}
                                    style={{
                                      WebkitAppearance: "none",
                                      MozAppearance: "textfield",
                                    }}
                                  />
                                </label>
                                {input.balance > 0 && (
                                  <button
                                    type="button"
                                    data-test="max-input"
                                    className="absolute top-0 right-0 -mt-[1px] -mr-[1px] rounded-tr-xl rounded-bl-xl !rounded-tl-none !rounded-br-none border !px-2 !py-0.5 !text-sm border-water-800 bg-gradient-to-br from-[#263854] to-[#3D575E]"
                                    // onClick={() => {
                                    //   setInputAux(
                                    //     input.balance?.toFixed(8) - 0.00000001
                                    //   );
                                    //   getPrice(
                                    //     input.balance?.toFixed(8) - 0.00000001
                                    //   );
                                    // }}
                                  >
                                    Max
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center order-2 py-8">
                          <button
                            data-test="swap-direction-button"
                            type="button"
                            className="inline-block p-2 rounded-full bg-water-900 hover:bg-water-800"
                            onClick={() => onChangeChains()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
                              ></path>
                            </svg>
                          </button>
                        </div>
                        <div className="order-3">
                          <div data-test="output">
                            <div className="flex">
                              <div className="">
                                <div className="text-base font-medium text-primary">
                                  <span className="inline-block">To</span>
                                </div>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <div className="font-medium"></div>
                              </div>
                            </div>
                            <div className="mt-2.5 flex h-12 items-center rounded-xl border text-white bg-water-900 bg-opacity-40 border-water-800">
                              <div className="w-1/2 h-full border-water-800">
                                <button
                                  type="button"
                                  onClick={() => openChainsModal("to")}
                                  for=""
                                  className="flex h-full w-full items-center justify-between px-2.5 py-1 gap-x-1"
                                >
                                  <div className="inline-flex items-center overflow-x-hidden shrink">
                                    <img
                                      src={chainTo.src}
                                      alt={chainTo.name || "Select Chain"}
                                      className="inline-block h-6 w-6 max-w-none shrink-0 bl-0002"
                                    />
                                    <div className="ml-2 flex flex-col w-full overflow-x-hidden">
                                      <span className="inline-flex items-center w-full overflow-x-hidden font-semibold text-base">
                                        <span className="truncate" title="USDT">
                                          {chainTo.name || "Select Chain"}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <svg
                                    width="14"
                                    height="8"
                                    viewBox="0 0 14 8"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="shrink-0"
                                  >
                                    <path d="M7.00023 7.71309L13.0102 1.70309L11.5972 0.288086L7.00023 4.88809L2.40423 0.288086L0.990234 1.70209L7.00023 7.71309Z"></path>
                                  </svg>
                                </button>
                              </div>
                              <div className="relative w-1/2 h-full overflow-hidden border-l border-water-800">
                                <label
                                  for="pp-token-amount-input-2840"
                                  className="flex flex-col justify-center h-full"
                                >
                                  <input
                                    className="pp-input-field w-full py-0 pl-2 pr-1 input-number text-base font-semibold bg-transparent border-none placeholder:font-light focus:outline-none focus:ring-0 placeholder:text-water-500 bl-0005"
                                    step="any"
                                    autocomplete="off"
                                    readonly=""
                                    type="text"
                                    inputmode="decimal"
                                    id="pp-token-amount-input-2840"
                                    data-test="input"
                                    placeholder="0.00"
                                    value={output.value}
                                    disabled
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pp-card-body py-8 space-y-3 text-base border-t border-water-500">
                      <div>
                        <div className="">
                          <div className="space-y-4">
                            <div className="flex">
                              <div className="">
                                <span data-v-tippy="">Bridge Rate </span>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <button
                                  type="button"
                                  className="inline-flex cursor-pointer flex-wrap justify-end overflow-hidden font-semibold text-primary"
                                  data-test="rate"
                                >
                                  <div className="inline-block">
                                    <div className="flex items-center gap-x-1 whitespace-nowrap">
                                      <span
                                        title="0"
                                        className="whitespace-nowrap"
                                      >
                                        {ratio ? 1 : 0}
                                      </span>
                                      <span className="inline-flex items-center">
                                        <span
                                          className=""
                                          style={{ marginRight: "10px" }}
                                        >
                                          {tokenInput.symbol
                                            ? tokenInput.symbol
                                            : "Select Token"}
                                        </span>
                                        on
                                        <img
                                          data-v-854fb5b8=""
                                          src={chainFrom.src}
                                          alt={chainFrom.name || "Select Chain"}
                                          className="inline-block h-6 w-6 rounded-full bl-0006"
                                          style={{ padding: "4px" }}
                                        />
                                      </span>
                                    </div>
                                  </div>

                                  <div className="inline-block">
                                    <div className="flex items-center gap-x-1 whitespace-nowrap">
                                      <span className="mx-2 ">=</span>
                                      <span
                                        title="0"
                                        className="whitespace-nowrap"
                                      >
                                        {ratio ? ratio : 0}
                                      </span>
                                      <span className="inline-flex items-center">
                                        <span
                                          className=""
                                          style={{ marginRight: "10px" }}
                                        >
                                          {tokenInput.symbol
                                            ? tokenInput.symbol
                                            : "Select Token"}
                                        </span>
                                        on
                                        <img
                                          data-v-854fb5b8=""
                                          src={chainTo.src}
                                          alt={chainTo.name || "Select Chain"}
                                          className="inline-block h-6 w-6 rounded-full bl-0006"
                                          style={{ padding: "4px" }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                            <div className="flex">
                              <div className="">
                                <span data-v-tippy="">Estimated Gás</span>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <div
                                  className="font-semibold text-primary flex gap-x-1.5 items-center"
                                  data-test="min-received"
                                >
                                  <span
                                    title="USD 0"
                                    className="whitespace-nowrap"
                                  >
                                    {estimatedGasUsd
                                      ? `$${parseFloat(estimatedGasUsd).toFixed(
                                          2
                                        )}`
                                      : "$0.00"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* <div className="flex">
                              <div className="">
                                <span data-v-tippy="">Slippage </span>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <div
                                  className="font-semibold text-primary flex gap-x-1.5 items-center"
                                  data-test="min-received"
                                >
                                  <div className="inline-block">
                                    <div className="flex items-center gap-x-1 whitespace-nowrap">
                                      <span
                                        title="0"
                                        className="whitespace-nowrap"
                                        style={
                                          slippageAmount < 3
                                            ? { color: "green" }
                                            : slippageAmount < 10
                                            ? { color: "orange" }
                                            : { color: "red" }
                                        }
                                      >
                                        {slippageAmount
                                          ? `${slippageAmount}%`
                                          : "0%"}
                                      </span>
                                    </div>
                                  </div>
                                  
                                </div>
                              </div>
                            </div> */}

                            <div>
                              <div
                                data-v-854fb5b8=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-854fb5b8=""
                                  className="flex items-center flex-grow"
                                  title="swap"
                                >
                                  <img
                                    data-v-854fb5b8=""
                                    src={chainFrom.src}
                                    alt={chainFrom.name || "Select Chain"}
                                    className="inline-block h-6 w-6 rounded-full bl-0006"
                                  />
                                  <div data-v-854fb5b8="" className="w-full">
                                    <span data-v-854fb5b8="" data-v-tippy="">
                                      <div
                                        data-v-854fb5b8=""
                                        className="w-full px-2 py-2"
                                      >
                                        <div
                                          data-v-854fb5b8=""
                                          className="line-h"
                                        ></div>
                                      </div>
                                    </span>
                                  </div>
                                  <img
                                    data-v-854fb5b8=""
                                    src={chainTo.src}
                                    alt={chainTo.name || "Select Chain"}
                                    className="inline-block h-6 w-6 rounded-full bl-0007"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pp-card-actions">
                      <div className="flex flex-wrap gap-4">
                        {loading ? (
                          <>
                            <button
                              className="flex-1 pp-btn px-4 py-2.5 text-md rounded-5xl pp-focus-outline btn-gradient-turquoise-blue font-bold text-white bg-pos-0 border-none bg-size-400 hover:bg-pos-33 focus:bg-pos-100 transition-all duration-300 flex-1 disableButton"
                              disabled
                            >
                              <ReactLoading
                                type="spin"
                                // color={color}
                                height={"5%"}
                                width={"5%"}
                                className="balance-loading-spin"
                              />
                            </button>
                          </>
                        ) : (
                          <>
                            {isConnected() ? (
                              parseFloat(input.balance) >
                              parseFloat(aproveAmount) ? (
                                <button
                                  type="button"
                                  onClick={() =>
                                    runSwap(
                                      transaction,
                                      signer,
                                      aproveAmount,
                                      tokenInput
                                    )
                                  }
                                  className="flex-1 pp-btn px-4 py-2.5 text-md rounded-5xl pp-focus-outline btn-gradient-turquoise-blue font-bold text-white bg-pos-0 border-none bg-size-400 hover:bg-pos-33 focus:bg-pos-100 transition-all duration-300 flex-1"
                                >
                                  Transfer Tokens
                                </button>
                              ) : (
                                <button
                                  className="flex-1 pp-btn px-4 py-2.5 text-md rounded-5xl pp-focus-outline btn-gradient-turquoise-blue font-bold text-white bg-pos-0 border-none bg-size-400 hover:bg-pos-33 focus:bg-pos-100 transition-all duration-300 flex-1 disableButton"
                                  disabled
                                >
                                  Bridge Tokens
                                  {/* {aproveAmount
                                    ? `Insufficient ${input.tokenName} balance`
                                    : "Enter Amount"} */}
                                </button>
                              )
                            ) : (
                              <button
                                type="button"
                                onClick={() => getSigner(provider)}
                                className="flex-1 pp-btn px-4 py-2.5 text-md rounded-5xl pp-focus-outline btn-gradient-turquoise-blue font-bold text-white bg-pos-0 border-none bg-size-400 hover:bg-pos-33 focus:bg-pos-100 transition-all duration-300 flex-1"
                              >
                                Connect Wallet
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default DappBridge;
