import React, { useEffect, useState } from "react";
import "./styles.css";
import "../dapp.css";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import iconBalance from "../../../assets/images/icons/blue.png";
import iconUsdt from "../../../assets/images/icons/usdt.svg";

import settingsIcon from "../../../assets/settings.svg";
import ConfigModal from "../../../components/ConfigModal";
import TokensModal from "../../../components/TokensModal";
import ReactLoading from "react-loading";

import { tokensList } from "../../../tokens";

const DappSwap = ({
  showModal,
  setShowModal,
  setDeadlineMinutes,
  deadlineMinutes,
  setSlippageAmount,
  slippageAmount,
  input,
  output,
  onChangeTokens,
  isConnected,
  runSwap,
  transaction,
  signer,
  aproveAmount,
  getSigner,
  provider,
  signerAddress,
  ratio,
  estimatedGasUsd,
  loading,
  tokenInput,
  setTokenInput,
  tokenOutput,
  setTokenOutput,
  inputAux,
  setInputAux,
  chains,
  setChainSelected,
  chainSelected
}) => {
  const [showTokensModal, setShowTokensModal] = useState(false);
  const isConnectedBoolean = isConnected();
  var displayAddress = `${signerAddress?.substring(0, 10)}...`;
  const getPrice = (value) => {
    if (value > 0) input.getSwapPrice(value);
  };
  const openTokensModal = (type) => {
    setShowTokensModal(type);
  };
  useEffect(() => {
    if (isConnectedBoolean == true) {
      displayAddress = `${signerAddress?.substring(0, 10)}...`;
      getPrice(inputAux);
    }
  }, [isConnectedBoolean]);
  return (
    <>
      <Header
        isConnected={isConnected}
        displayAddress={displayAddress}
        getSigner={getSigner}
        provider={provider}
        chains={chains}
                chainSelected={chainSelected}
                setChainSelected={setChainSelected}
      />
      <section className="dapp-section">
        <div className="w-full flex justify-center">
          <div className="pb-3 pp-card bg-water-200 bg-opacity-[0.07] text-water-300 pb-3 bl-0001">
            <div className="border-b border-water-500">
              <div data-test="action-selector">
                <div
                  role="tablist"
                  aria-orientation="horizontal"
                  className="flex pp-tab-items relative"
                  modelvalue="0"
                  variant="text"
                >
                  <div
                    className="pp-tab pp-btn relative flex-1 !border px-2.5 py-1.5 text-base rounded-2xl text-water-300 enabled:hover:text-water-200 focus:text-water-200 transition pt-4 !text-white !font-medium"
                    id="headlessui-tabs-tab-33"
                    role="tab"
                    aria-selected="true"
                    tabindex="0"
                    text="Swap"
                    name="ProSwap"
                  >
                    <span>Swap</span>
                    <div className="absolute -bottom-[0.5px] h-0.5 w-full translate-y-full transform rounded-full transition bg-white"></div>
                  </div>
                  
                  <button
                    className="configButton"
                    onClick={() => setShowModal(true)}
                  >
                    <img width="24px" height="24px" src={settingsIcon} alt="" />
                  </button>
                  {showModal && (
                    <ConfigModal
                      onClose={() => {setShowModal(false)}}
                      setDeadlineMinutes={setDeadlineMinutes}
                      deadlineMinutes={deadlineMinutes}
                      setSlippageAmount={setSlippageAmount}
                      slippageAmount={slippageAmount}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="pt-6">
              <div>
                <div data-v-bd3d1223="">
                  <form novalidate="">
                    <div className="pp-card-body pb-8 text-water-300">
                      <div className="flex flex-col">
                        <div className="order-1">
                          <div data-test="input">
                            <div className="flex">
                              <div className="">
                                <div className="text-base font-medium text-primary">
                                  <span className="inline-block">Input</span>
                                </div>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                {(input.balance || input.balance == 0) && (
                                  <div className="font-medium">
                                    Balance:{" "}
                                    <div
                                      className="inline-block"
                                      data-test="balance"
                                    >
                                      <div className="flex items-center gap-x-1 whitespace-nowrap">
                                        <span
                                          title="0"
                                          className="whitespace-nowrap"
                                          style={{ marginLeft: "2px" }}
                                        >
                                          {input.balance?.toFixed(8)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mt-2.5 flex h-12 items-center rounded-xl border text-white bg-water-900 bg-opacity-40 border-water-800">
                              <div className="w-1/2 h-full border-water-800">
                                <button
                                  type="button"
                                  onClick={() => openTokensModal("input")}
                                  for=""
                                  className="flex h-full w-full items-center justify-between px-2.5 py-1 gap-x-1"
                                >
                                  <div className="inline-flex items-center overflow-x-hidden shrink">
                                    <img
                                      src={tokenInput.icon}
                                      alt={tokenInput.symbol || "Select Token"}
                                      className="inline-block h-6 w-6 max-w-none shrink-0 bl-0002"
                                    />
                                    <div className="ml-2 flex flex-col w-full overflow-x-hidden">
                                      <span className="inline-flex items-center w-full overflow-x-hidden font-semibold text-base">
                                        <span className="truncate" title="USDT">
                                          {tokenInput.symbol || "Select Token"}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <svg
                                    width="14"
                                    height="8"
                                    viewBox="0 0 14 8"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="shrink-0"
                                  >
                                    <path d="M7.00023 7.71309L13.0102 1.70309L11.5972 0.288086L7.00023 4.88809L2.40423 0.288086L0.990234 1.70209L7.00023 7.71309Z"></path>
                                  </svg>
                                </button>
                                {showTokensModal === "input" ? (
                                  <TokensModal
                                    onClose={() => setShowTokensModal(false)}
                                    handleSelectToken={setTokenInput}
                                    tokens={tokensList}
                                    desabledToken={tokenOutput}
                                  />
                                ) : (
                                  showTokensModal === "output" && (
                                    <TokensModal
                                      onClose={() => setShowTokensModal(false)}
                                      handleSelectToken={setTokenOutput}
                                      tokens={tokensList}
                                      desabledToken={tokenInput}
                                    />
                                  )
                                )}
                              </div>
                              <div className="relative w-1/2 h-full overflow-hidden border-l border-water-800">
                                <label
                                  for="pp-token-amount-input-2828"
                                  className="flex flex-col justify-center h-full"
                                >
                                  <input
                                    className="pp-input-field w-full py-0 pl-2 input-number pr-1 text-base font-semibold bg-transparent border-none placeholder:font-light focus:outline-none focus:ring-0 placeholder:text-water-500 bl-0003"
                                    step="any"
                                    placeholder="0.00"
                                    autocomplete="off"
                                    type="text"
                                    inputmode="decimal"
                                    id="pp-token-amount-input-2828"
                                    data-test="input"
                                    value={inputAux}
                                    onChange={(e) => {
                                      setInputAux(e.target.value);
                                    }}
                                    onBlur={(e) => getPrice(e.target.value)}
                                    style={{
                                      WebkitAppearance: "none",
                                      MozAppearance: "textfield",
                                    }}
                                  />
                                </label>
                                {input.balance > 0 && (
                                  <button
                                    type="button"
                                    data-test="max-input"
                                    className="absolute top-0 right-0 -mt-[1px] -mr-[1px] rounded-tr-xl rounded-bl-xl !rounded-tl-none !rounded-br-none border !px-2 !py-0.5 !text-sm border-water-800 bg-gradient-to-br from-[#263854] to-[#3D575E]"
                                    onClick={() => {
                                      setInputAux(
                                        input.balance?.toFixed(8) - 0.00000001
                                      );
                                      getPrice(
                                        input.balance?.toFixed(8) - 0.00000001
                                      );
                                    }}
                                  >
                                    Max
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center order-2 py-8">
                          <button
                            data-test="swap-direction-button"
                            type="button"
                            className="inline-block p-2 rounded-full bg-water-900 hover:bg-water-800"
                            onClick={() => onChangeTokens()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
                              ></path>
                            </svg>
                          </button>
                        </div>
                        <div className="order-3">
                          <div data-test="output">
                            <div className="flex">
                              <div className="">
                                <div className="text-base font-medium text-primary">
                                  <span className="inline-block">Output</span>
                                </div>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <div className="font-medium"></div>
                              </div>
                            </div>
                            <div className="mt-2.5 flex h-12 items-center rounded-xl border text-white bg-water-900 bg-opacity-40 border-water-800">
                              <div className="w-1/2 h-full border-water-800">
                                <button
                                  type="button"
                                  onClick={() => openTokensModal("output")}
                                  for=""
                                  className="flex h-full w-full items-center justify-between px-2.5 py-1 gap-x-1"
                                >
                                  <div className="inline-flex items-center overflow-x-hidden shrink">
                                    <img
                                      src={tokenOutput.icon}
                                      alt={tokenOutput.symbol || "Select Token"}
                                      className="inline-block h-6 w-6 max-w-none shrink-0 bl-0002"
                                    />
                                    <div className="ml-2 flex flex-col w-full overflow-x-hidden">
                                      <span className="inline-flex items-center w-full overflow-x-hidden font-semibold text-base">
                                        <span className="truncate" title="USDT">
                                          {tokenOutput.symbol || "Select Token"}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <svg
                                    width="14"
                                    height="8"
                                    viewBox="0 0 14 8"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="shrink-0"
                                  >
                                    <path d="M7.00023 7.71309L13.0102 1.70309L11.5972 0.288086L7.00023 4.88809L2.40423 0.288086L0.990234 1.70209L7.00023 7.71309Z"></path>
                                  </svg>
                                </button>
                              </div>
                              <div className="relative w-1/2 h-full overflow-hidden border-l border-water-800">
                                <label
                                  for="pp-token-amount-input-2840"
                                  className="flex flex-col justify-center h-full"
                                >
                                  <input
                                    className="pp-input-field w-full py-0 pl-2 pr-1 input-number text-base font-semibold bg-transparent border-none placeholder:font-light focus:outline-none focus:ring-0 placeholder:text-water-500 bl-0005"
                                    step="any"
                                    autocomplete="off"
                                    readonly=""
                                    type="text"
                                    inputmode="decimal"
                                    id="pp-token-amount-input-2840"
                                    data-test="input"
                                    placeholder="0.00"
                                    value={output.value}
                                    disabled
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pp-card-body py-8 space-y-3 text-base border-t border-water-500">
                      <div>
                        <div className="">
                          <div className="space-y-4">
                            <div className="flex">
                              <div className="">
                                <span data-v-tippy=""> Rate </span>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <button
                                  type="button"
                                  className="inline-flex cursor-pointer flex-wrap justify-end overflow-hidden font-semibold text-primary"
                                  data-test="rate"
                                >
                                  <div className="inline-block">
                                    <div className="flex items-center gap-x-1 whitespace-nowrap">
                                      <span
                                        title="0"
                                        className="whitespace-nowrap"
                                      >
                                        {ratio ? 1 : 0}
                                      </span>
                                      <span className="inline-flex items-center">
                                        <span className="" title="BALANCE">
                                          {tokenInput.symbol
                                            ? tokenInput.symbol
                                            : "Select Token"}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <span className="mx-2">=</span>
                                  <div className="inline-block">
                                    <div className="flex items-center gap-x-1 whitespace-nowrap">
                                      <span
                                        title="0"
                                        className="whitespace-nowrap"
                                      >
                                        {ratio ? ratio : 0}
                                      </span>
                                      <span className="inline-flex items-center">
                                        <span className="" title="USDT">
                                          {tokenOutput.symbol
                                            ? tokenOutput.symbol
                                            : "Select Token"}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                            <div className="flex">
                              <div className="">
                                <span data-v-tippy="">Estimated Gás</span>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <div
                                  className="font-semibold text-primary flex gap-x-1.5 items-center"
                                  data-test="min-received"
                                >
                                  <span
                                    title="USD 0"
                                    className="whitespace-nowrap"
                                  >
                                    {estimatedGasUsd
                                      ? `$${parseFloat(estimatedGasUsd).toFixed(
                                          2
                                        )}`
                                      : "$0.00"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="flex">
                              <div className="">
                                <span data-v-tippy="">Slippage </span>
                              </div>
                              <div className="flex-grow ml-2"></div>
                              <div className="">
                                <div
                                  className="font-semibold text-primary flex gap-x-1.5 items-center"
                                  data-test="min-received"
                                >
                                  <div className="inline-block">
                                    <div className="flex items-center gap-x-1 whitespace-nowrap">
                                      <span
                                        title="0"
                                        className="whitespace-nowrap"
                                        style={
                                          slippageAmount < 3
                                            ? { color: "green" }
                                            : slippageAmount < 10
                                            ? { color: "orange" }
                                            : { color: "red" }
                                        }
                                      >
                                        {slippageAmount
                                          ? `${slippageAmount}%`
                                          : "0%"}
                                      </span>
                                    </div>
                                  </div>
                                  {/* <span
                                    data-v-tippy=""
                                    className="leading-none"
                                  >
                                    <svg
                                      width="26"
                                      height="26"
                                      viewBox="0 0 26 26"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="inline-block w-4 h-4 text-grass"
                                    >
                                      <path d="M13 11.75C12.6685 11.75 12.3505 11.8817 12.1161 12.1161C11.8817 12.3505 11.75 12.6685 11.75 13V18C11.75 18.3315 11.8817 18.6495 12.1161 18.8839C12.3505 19.1183 12.6685 19.25 13 19.25C13.3315 19.25 13.6495 19.1183 13.8839 18.8839C14.1183 18.6495 14.25 18.3315 14.25 18V13C14.25 12.6685 14.1183 12.3505 13.8839 12.1161C13.6495 11.8817 13.3315 11.75 13 11.75ZM13.475 6.85C13.1707 6.72498 12.8293 6.72498 12.525 6.85C12.3716 6.90949 12.2314 6.9987 12.1125 7.1125C12.0021 7.234 11.9133 7.37352 11.85 7.525C11.78 7.67335 11.7458 7.83603 11.75 8C11.7491 8.16451 11.7806 8.32759 11.8428 8.47988C11.905 8.63218 11.9967 8.7707 12.1125 8.8875C12.234 8.99791 12.3735 9.0867 12.525 9.15C12.7144 9.2278 12.92 9.2579 13.1237 9.23764C13.3274 9.21739 13.523 9.1474 13.6934 9.03384C13.8637 8.92027 14.0036 8.7666 14.1006 8.58634C14.1977 8.40607 14.249 8.20473 14.25 8C14.2454 7.66904 14.1159 7.35204 13.8875 7.1125C13.7686 6.9987 13.6284 6.90949 13.475 6.85ZM13 0.5C10.5277 0.5 8.11099 1.23311 6.05538 2.60663C3.99976 3.98015 2.39761 5.93238 1.45151 8.21646C0.505416 10.5005 0.257874 13.0139 0.74019 15.4386C1.2225 17.8634 2.41301 20.0907 4.16117 21.8388C5.90933 23.587 8.13661 24.7775 10.5614 25.2598C12.9861 25.7421 15.4995 25.4946 17.7836 24.5485C20.0676 23.6024 22.0199 22.0002 23.3934 19.9446C24.7669 17.889 25.5 15.4723 25.5 13C25.5 11.3585 25.1767 9.73303 24.5485 8.21646C23.9203 6.69989 22.9996 5.3219 21.8388 4.16117C20.6781 3.00043 19.3001 2.07969 17.7836 1.45151C16.267 0.823322 14.6415 0.5 13 0.5ZM13 23C11.0222 23 9.08879 22.4135 7.4443 21.3147C5.79981 20.2159 4.51809 18.6541 3.76121 16.8268C3.00433 14.9996 2.8063 12.9889 3.19215 11.0491C3.57801 9.10929 4.53041 7.32746 5.92894 5.92893C7.32746 4.53041 9.10929 3.578 11.0491 3.19215C12.9889 2.8063 14.9996 3.00433 16.8268 3.7612C18.6541 4.51808 20.2159 5.79981 21.3147 7.4443C22.4135 9.08879 23 11.0222 23 13C23 15.6522 21.9464 18.1957 20.0711 20.0711C18.1957 21.9464 15.6522 23 13 23Z"></path>
                                    </svg>
                                  </span> */}
                                </div>
                              </div>
                            </div>

                            <div>
                              <div
                                data-v-854fb5b8=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-854fb5b8=""
                                  className="flex items-center flex-grow"
                                  title="swap"
                                >
                                  <img
                                    data-v-854fb5b8=""
                                    src={tokenInput.icon}
                                    alt={tokenInput.symbol || "Select Token"}
                                    className="inline-block h-6 w-6 rounded-full bl-0006"
                                  />
                                  <div data-v-854fb5b8="" className="w-full">
                                    <span data-v-854fb5b8="" data-v-tippy="">
                                      <div
                                        data-v-854fb5b8=""
                                        className="w-full px-2 py-2"
                                      >
                                        <div
                                          data-v-854fb5b8=""
                                          className="line-h"
                                        ></div>
                                      </div>
                                    </span>
                                  </div>
                                  <img
                                    data-v-854fb5b8=""
                                    src={tokenOutput.icon}
                                    alt={tokenOutput.symbol || "Select Token"}
                                    className="inline-block h-6 w-6 rounded-full bl-0007"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pp-card-actions">
                      <div className="flex flex-wrap gap-4">
                        {loading ? (
                          <>
                            <button
                              className="flex-1 pp-btn px-4 py-2.5 text-md rounded-5xl pp-focus-outline btn-gradient-turquoise-blue font-bold text-white bg-pos-0 border-none bg-size-400 hover:bg-pos-33 focus:bg-pos-100 transition-all duration-300 flex-1 disableButton"
                              disabled
                            >
                              <ReactLoading
                                type="spin"
                                // color={color}
                                height={"5%"}
                                width={"5%"}
                                className="balance-loading-spin"
                              />
                            </button>
                          </>
                        ) : (
                          <>
                            {isConnected() ? (
                              parseFloat(input.balance) >
                              parseFloat(aproveAmount) ? (
                                <button
                                  type="button"
                                  onClick={() =>
                                    runSwap(
                                      transaction,
                                      signer,
                                      aproveAmount,
                                      tokenInput
                                    )
                                  }
                                  className="flex-1 pp-btn px-4 py-2.5 text-md rounded-5xl pp-focus-outline btn-gradient-turquoise-blue font-bold text-white bg-pos-0 border-none bg-size-400 hover:bg-pos-33 focus:bg-pos-100 transition-all duration-300 flex-1"
                                >
                                  Swap Tokens
                                </button>
                              ) : (
                                <button
                                  className="flex-1 pp-btn px-4 py-2.5 text-md rounded-5xl pp-focus-outline btn-gradient-turquoise-blue font-bold text-white bg-pos-0 border-none bg-size-400 hover:bg-pos-33 focus:bg-pos-100 transition-all duration-300 flex-1 disableButton"
                                  disabled
                                >
                                  {aproveAmount
                                    ? `Insufficient ${input.tokenName} balance`
                                    : "Enter Amount"}
                                </button>
                              )
                            ) : (
                              <button
                                type="button"
                                onClick={() => getSigner(provider)}
                                className="flex-1 pp-btn px-4 py-2.5 text-md rounded-5xl pp-focus-outline btn-gradient-turquoise-blue font-bold text-white bg-pos-0 border-none bg-size-400 hover:bg-pos-33 focus:bg-pos-100 transition-all duration-300 flex-1"
                              >
                                Connect Wallet
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default DappSwap;
