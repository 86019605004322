import React from "react";
import "./ConfigModal.css";

const ConfigModalBridge = (props) => {
  return (
    <div className="modaly" onClick={props.onClose}>
      <div className="modaly-closer" onClick={props.onClose}></div>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-body">
          <div className="order-1">
            <div data-test="input">
              <div className="flex">
                <div className="">
                  <div className="text-base font-medium text-primary">
                    <span className="inline-block">Slippage Tolerance</span>
                  </div>
                </div>
              </div>
              <div className="mt-2.5 flex h-12 items-center rounded-xl border text-white bg-water-900 bg-opacity-40 border-water-800">
                <label
                  for="pp-token-amount-input-2828"
                  className="relative  flex flex-col justify-center h-full"
                >
                  <input
                    className="pp-input-field w-full py-0 pl-2 input-number pr-1 text-base font-semibold bg-transparent border-none placeholder:font-light focus:outline-none focus:ring-0 placeholder:text-water-500 bl-0003"
                    step="any"
                    autocomplete="off"
                    type="text"
                    inputmode="decimal"
                    id="pp-token-amount-input-2828"
                    data-test="input"
                    placeholder="0%"
                    value={props.slippageAmount}
                    onChange={(e) => {
                      if (
                        e.target.value >= 0 &&
                        e.target.value <= 100
                      ) {
                        props.setSlippageAmount(e.target.value);
                      }
                    }}
                  />
                  <label className="iso-input">%</label>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigModalBridge;
